/* You can add global styles to this file, and also import other style files */

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.snack-bar-success {
    background-color: #2ECC71;
    color: #fff;

    .mat-mdc-snack-bar-action {
        color: #fff;
    }
}

.snack-bar-error {
    background-color: #e63946;
    color: #fff;

    .mat-mdc-snack-bar-action {
        color: black;
    }
}
mat-header-cell, mat-cell {
    justify-content: center;
  }
